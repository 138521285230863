<template>
  <div class="menu" v-show="isVisible">
    <div class="menu-item">
      <router-link to="/" @click="hideMenu">Dashboard</router-link>
    </div>
    <div class="menu-item" v-show="hasRoleLevel('reviewer')">
      <router-link @click="hideMenu" to="/review">Review Files</router-link>
    </div>
    <div class="menu-item" v-show="hasRoleLevel('admin')">
      <router-link @click="hideMenu" to="/admin">Admin Page</router-link>
    </div>
    <div class="menu-item">
      <a
        href="javascript:"
        @click="
          () => {
            logout();
            hideMenu();
          }
        "
        >logout</a
      >
    </div>
  </div>
</template>

<script>
import { logout, hasRoleLevel } from '../lib/util';
import closeOnEscape from './mixins/close-on-escape';

export default {
  mixins: [closeOnEscape],
  created() {
    this.closeModes = ['escape', 'click'];
  },
  methods: {
    logout() {
      logout();
      if (this.$route.path === '/') {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
    },
    hasRoleLevel(requiredRole) {
      return hasRoleLevel(requiredRole);
    },
    hideMenu() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.menu {
  background-color: whitesmoke;
  position: absolute;
  margin-top: 10px;
  padding: 10px;
  min-width: 130px;
  right: 0;
  border: 1px solid grey;
  border-radius: 4px;
  z-index: 10;

  .menu-item {
    padding: 5px 0;
    white-space: nowrap;
    a {
      text-decoration: none;
      color: black;
      cursor: pointer;
    }
  }
}
</style>
