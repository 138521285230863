// duplicate from ap-service

export const BLACKLIST = [
  'https://ad.71i.de/images/fallback/video/1_sek.mp4',
  'https://ad.71i.de/images/fallback/video/1_sek.flv',
  'https://ad.71i.de/images/fallback/video/1_sek.m3u8',
  'https://imasdk.googleapis.com/js/sdkloader/vpaid_adapter.js',
  /\/api\/manifest\/dash\/requiressl\/yes\/source\//,
  /https:\/\/gcdn\.2mdn\.net\/videoplayback\/id\/b6c6bcb13bd259da/,
  /https:\/\/gcdn\.2mdn\.net\/videoplayback\/id\/515b45367ff867b3/,
  /https:\/\/gcdn\.2mdn\.net\/videoplayback\/id\/9153c624a47e765a/, // Kika Wien
  /https:\/\/gcdn\.2mdn\.net\/videoplayback\/id\/fdb03014dbcea32f/, // schärdinger.at
  /https:\/\/gcdn\.2mdn\.net\/videoplayback\/id\/9d8008f51e561ebb/, // post.at
  /\.js(#|$)/,
  /\.m3u8$/,
  /cd\.sqrt-5041.de/
];

export default function isBlacklisted(url) {
  return BLACKLIST.some(item => {
    return item instanceof RegExp ? item.test(url) : item === url;
  });
}
