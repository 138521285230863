import { reactive, readonly } from 'vue';
import * as libQueueStatus from '../lib/queue-status';
let timeout;
let intervalId;

const state = reactive({
  itemsQueueLength: undefined,
  urlsQueueLength: undefined,
  lastMinute: {
    itemsprocessed: undefined,
    urlsprocessed: undefined,
    itemsvalidcache: undefined,
    urlsvalidcache: undefined,
    itemsupdatedcache: undefined,
    urlsupdatedcache: undefined,
    errored: undefined,
    filechanged: undefined,
    fileadded: undefined
  },
  lastHour: {
    itemsprocessed: undefined,
    urlsprocessed: undefined,
    itemsvalidcache: undefined,
    urlsvalidcache: undefined,
    itemsupdatedcache: undefined,
    urlsupdatedcache: undefined,
    errored: undefined,
    filechanged: undefined,
    fileadded: undefined
  },
  today: {
    itemsprocessed: undefined,
    urlsprocessed: undefined,
    itemsvalidcache: undefined,
    urlsvalidcache: undefined,
    itemsupdatedcache: undefined,
    urlsupdatedcache: undefined,
    errored: undefined,
    filechanged: undefined,
    fileadded: undefined
  },
  last24Hours: {
    itemsprocessed: undefined,
    urlsprocessed: undefined,
    itemsvalidcache: undefined,
    urlsvalidcache: undefined,
    itemsupdatedcache: undefined,
    urlsupdatedcache: undefined,
    errored: undefined,
    filechanged: undefined,
    fileadded: undefined
  }
});

const methods = {
  get: (fromSubscribe = false) => {
    if (!fromSubscribe && intervalId) {
      methods.subscribe(timeout);
      return;
    }
    libQueueStatus.get().then(result => {
      Object.assign(state, result);
    });
  },
  subscribe: (interval = 60000) => {
    if (intervalId) {
      clearTimeout(intervalId);
      intervalId = undefined;
    }
    timeout = interval;
    methods.get(true);
    intervalId = setInterval(() => {
      methods.get(true);
    }, interval);
  }
};

export default { state: readonly(state), methods };
