import { getBaseUrl, deliverMock, readToken } from './util.js';
import mock from './mock/admin-user-data.json';

const IS_MOCK = false;

export async function get() {
  if (IS_MOCK) {
    return deliverMock('admin-user-data', mock);
  }

  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/admin/user-data`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      token: readToken()
    }
  }).then(data => data.json());
}

export async function update(user) {
  const body = JSON.stringify(user);
  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/admin/user-data`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      token: readToken()
    },
    body
  }).then(data => data.json());
}

export async function create(user) {
  const body = JSON.stringify(user);
  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/admin/user-data`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      token: readToken()
    },
    body
  }).then(data => data.json());
}
