<template>
  <div class="search-output">
    <section>
      <table class="result-table">
        <div class="row-count-wrapper" v-show="internalData.length > perPage">
          rows per page:
          <select v-model.number="perPage">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="show-more-wrapper" v-show="internalData.length">
          <div
            class="show-more"
            v-show="!showMoreColumns"
            @click="showMoreColumns = true"
          >
            more columns <mdicon name="chevron-right" />
          </div>
          <div
            class="show-more"
            v-show="showMoreColumns"
            @click="showMoreColumns = false"
          >
            <mdicon name="chevron-left" /> less columns
          </div>
        </div>
        <tr v-show="currentPageContent.length">
          <th></th>
          <th
            v-for="c in tableColumns"
            :key="c"
            v-show="showMoreColumns || !c.optional"
            v-text="getHeaderText(c.field)"
            @click="toggleSort(c.field)"
            :style="{
              textAlign: c.position || 'left',
              width: c.width ? `${c.width}px` : 'default'
            }"
          ></th>
        </tr>
        <template v-for="(row, index) in currentPageContent" :key="row">
          <tr>
            <td class="toggle" @click="toggleRow(index)">
              <mdicon v-show="index !== enhancedRow" name="plus" size="16" />
              <mdicon v-show="index === enhancedRow" name="minus" size="16" />
            </td>
            <td
              v-for="c in tableColumns"
              v-show="showMoreColumns || !c.optional"
              :key="c"
              :class="c.field"
              :style="{
                textAlign: c.position || 'left',
                width: c.width ? `${c.width}px` : 'default',
                whiteSpace: 'nowrap',
                fontSize: c.fontSize || 'inherit'
              }"
            >
              <span
                v-if="!['url', 'fingerprint', 'screenshot'].includes(c.field)"
                v-text="f(row[c.field])"
              />
              <div v-if="c.field === 'url'" class="url-field">
                <div
                  class="url"
                  @click="toggleRow(index)"
                  v-text="row[c.field]"
                  :title="row[c.field]"
                />
                <mdicon
                  class="copy"
                  @click="copyLink(row[c.field])"
                  style="cursor: pointer"
                  size="15"
                  name="content-copy"
                />
                <a
                  :style="{
                    color: isDynamicGamUrl(row[c.field]) ? 'green' : '#ff0031'
                  }"
                  target="_playout"
                  :href="getPlayoutLink(row[c.field])"
                >
                  <mdicon size="16" name="play-box-outline" />
                </a>
              </div>
              <div v-if="c.field === 'screenshot'" class="screenshot-field">
                <img :src="row.screenshotUrl" class="screenshot" />
                <img :src="row.screenshotUrl" class="screenshot-bigger" />
              </div>
              <div v-if="c.field === 'fingerprint'" class="fingerprint-field">
                <div class="fingerprint" v-text="row[c.field]" />
                <mdicon
                  class="copy"
                  @click="setSearchValue(row[c.field])"
                  style="cursor: pointer"
                  size="15"
                  name="filter-outline"
                />
              </div>
            </td>
          </tr>
          <tr v-show="index === enhancedRow">
            <td></td>
            <td colspan="6" class="detail-td">
              <div class="chart-wrapper">
                <file-request-history-chart :data="fileRequestHistory" />
              </div>
              <pre>{{ row.details }}</pre>
            </td>
          </tr>
        </template>
      </table>
    </section>
    <section class="pagination" v-show="internalData.length > perPage">
      <o-pagination
        :total="internalData.length"
        v-model:current="currentPage"
        :per-page="perPage"
        range-before="0"
        range-after="0"
        order="centered"
        :icon-prev="'chevron-left'"
        :icon-next="'chevron-right'"
      >
      </o-pagination>
    </section>
    <div style="height: 100px" />
  </div>
</template>

<script>
import tableColumns from './file-search-table-columns.json';

function stripProtocol(url) {
  return url.replace(/^http(s?):\/\//, '');
}

export default {
  inject: ['store'],
  props: {
    tableData: Array,
    default: () => []
  },
  computed: {
    internalData() {
      return [...this.$props.tableData].sort((a, b) => {
        if (this.sortDirection === 'ASC') {
          if (a[this.sortField] > b[this.sortField]) return 1;
          return -1;
        }
        if (a[this.sortField] > b[this.sortField]) return -1;
        return 1;
      });
    },
    currentlyShownUrl() {
      return this.currentPageContent[this.enhancedRow]?.url;
    },
    fileRequestHistory() {
      return this.store.state.fileRequestHistory.history[
        this.currentlyShownUrl
      ];
    },
    currentPageContent() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.internalData.slice(start, start + this.perPage);
    }
  },
  watch: {
    tableData() {
      this.enhancedRow = -1;
    },
    enhancedRow(value) {
      if (value === -1) return;
      if (this.store.state.fileRequestHistory[this.currentlyShownUrl]) return;
      this.store.fileRequestHistory.get(this.currentlyShownUrl);
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      enhancedRow: -1,
      sortField: 'added',
      sortDirection: 'DESC',
      showMoreColumns: false,
      tableColumns
    };
  },
  methods: {
    getHeaderText(field) {
      const label = this.tableColumns.find(c => c.field === field).label;
      const sortSign =
        this.sortField === field
          ? this.sortDirection === 'ASC'
            ? '↑ '
            : '↓ '
          : '';
      return `${sortSign}${label}`;
    },
    isDynamicGamUrl(url) {
      const GOOGLE_DYNAMICURL_REGEX = /\/id\/\w+\/itag\/\d+\//;
      return GOOGLE_DYNAMICURL_REGEX.test(url);
    },
    getPlayoutLink(url) {
      if (this.isDynamicGamUrl(url)) {
        return `https://cd.sqrt-5041.de?url=${stripProtocol(url)}`;
      }
      return url;
    },
    toggleSort(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.sortField = field;
        this.sortDirection = 'ASC';
      }
      this.enhancedRow = -1;
      this.currentPage = 1;
    },
    toggleRow(index) {
      this.enhancedRow = this.enhancedRow === index ? -1 : index;
    },
    f(value) {
      if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(value)) {
        return value.substr(0, 10);
      }
      if (typeof value !== 'number') return value;
      return value.toLocaleString('de-DE');
    },
    copyLink(url) {
      navigator.clipboard.writeText(url);
    },
    setSearchValue(value) {
      this.$emit('set-search-value', value);
    }
  }
};
</script>

<style lang="scss">
.search-output {
  position: relative;
  height: 350px;
  margin-top: 30px;
  table.result-table {
    font-size: 15px;
    position: relative;
    .row-count-wrapper {
      position: absolute;
      top: -50px;
      right: 0;
    }
    .show-more-wrapper {
      position: absolute;
      top: 0;
      right: -25px;
      height: 0;
      .show-more {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 200px;
        text-align: right;
        cursor: pointer;
      }
    }
    th {
      cursor: pointer;
      white-space: nowrap;
      // background: rgb(250, 250, 250, 0.85);
      border-left: 1px solid #eee;
      padding: 0px 4px;

      &:nth-child(1) {
        background: inherit;
        border-left: none;
      }
    }
    td {
      padding: 0px 4px;
    }

    td.toggle {
      cursor: pointer;
      font-weight: bold;
      text-align: center;
    }
    td.detail-td {
      position: relative;
      .chart-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        height: 0;
      }
      pre {
        margin: 0;
        background: #fff;
        white-space: pre-wrap;
        max-width: 1200px;
        overflow-x: hidden;
      }
    }
    td.screenshot {
      padding: 0;
    }
    .url-field {
      display: flex;
      width: 644px;
      .url {
        flex-grow: 1;
        margin-right: 10px;
        cursor: pointer;
        max-width: 600px;
        overflow-x: auto;
      }
      a {
        margin-left: 3px;
        color: #ff0031;
      }
    }
    .fingerprint-field {
      display: flex;
      width: 110px;
      .fingerprint {
        flex-grow: 1;
        margin-right: 10px;
        width: 85px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;
        font-family: 'Courier New', Courier, monospace;
      }
    }
    .screenshot-field {
      position: relative;
      height: 20px;
      line-height: 10px;
      img.screenshot {
        height: 20px;
      }
      img.screenshot-bigger {
        position: absolute;
        display: none;
        top: -80px;
        left: 0;
        height: 100px;
      }
      &:hover {
        img.screenshot-bigger {
          display: block;
        }
      }
    }
  }
  .pagination {
    text-align: center;
    justify-content: center;

    .o-pag__link {
      border: 1px solid grey;
      border-radius: 4px;
      height: 30px;
      min-width: 30px;
      margin: 0 10px;
      color: $textDefault;

      &.o-pag__link--current {
        color: $accent;
        background: inherit;
        font-weight: bold;
      }
      &.o-pag__next::before {
        margin-top: -2px;
        font-weight: bold;
        content: '›';
      }
      &.o-pag__previous::before {
        margin-top: -2px;
        font-weight: bold;
        content: '‹';
      }
    }
  }
}
</style>
