<template>
  <div class="app-header">
    <div>
      <h1>
        <img class="lyra" src="@/assets/lyra.svg" alt="" />Apollo Dashboard
      </h1>
      <b>Media File Inspection Service</b>
    </div>
    <div class="logo">
      <img src="@/assets/logo-new.png" alt="" />
    </div>
    <div class="menu-wrapper">
      <mdicon
        name="menu"
        size="30"
        @click="showMenu = !showMenu"
        style="cursor: pointer"
      />
      <header-menu
        v-show="showMenu"
        :isVisible="showMenu"
        @close="showMenu = false"
      />
    </div>
  </div>
</template>

<script>
import { logout, hasRoleLevel } from '../lib/util';

export default {
  data() {
    return {
      showMenu: false
    };
  },
  methods: {
    logout() {
      logout();
      window.location.reload();
    },
    hasRoleLevel(requiredRole) {
      return hasRoleLevel(requiredRole);
    },
    hideMenu() {
      this.showMenu = false;
    }
  }
};
</script>

<style lang="scss">
.app-header {
  position: relative;
  margin: 30px 0 20px 0;
  display: flex;
  justify-content: space-between;
  h1 {
    margin: 0;
  }
  .lyra {
    height: 32px;
    margin-right: 3px;
    margin-bottom: -4px;
  }
  .logo {
    margin-top: 4px;
    margin-left: -60px;
    img {
      // border: 1px solid black;
      // width: 100px;
      height: 55px;
    }
  }
  .menu-wrapper {
    position: relative;
    top: 20px;
  }
}
</style>
