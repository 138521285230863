import { reactive, readonly } from 'vue';
import * as libReviewFiles from '../lib/review-files';
let timeout;
let intervalId;

const state = reactive({
  files: [],
  history: {}
});

const methods = {
  get: (fromSubscribe = false) => {
    if (!fromSubscribe && intervalId) {
      methods.subscribe(timeout);
      return;
    }
    libReviewFiles.get().then(result => {
      state.files = result;
    });
  },
  update: (fingerprint, command) => {
    libReviewFiles.update(fingerprint, command).then(result => {
      if (!result?.length) return;
      const index = state.files.findIndex(
        item => item.fingerprint === result[0].fingerprint
      );
      if (index > -1) state.files[index] = result[0];
    });
  },
  subscribe: (interval = 60000) => {
    if (intervalId) {
      clearTimeout(intervalId);
      intervalId = undefined;
    }
    timeout = interval;
    methods.get(true);
    intervalId = setInterval(() => {
      methods.get(true);
    }, interval);
  }
};

export default { state: readonly(state), methods };
