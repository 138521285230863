<template>
  <admin-user-management />
</template>

<script>
export default {
  inject: ['store'],
  name: 'AdminView'
};
</script>
