<template>
  <div class="queue-status">
    <collapse>
      <template v-slot:title>
        <h2>Queue Status</h2>
      </template>
      <p class="queue-length">
        <span class="label">waiting {{ mode }}:</span>
        <span class="value" v-text="status[`${mode}QueueLength`]" />
      </p>
      <table class="queue-status-table">
        <tr>
          <th>
            <span
              class="mode-switcher"
              :class="{ active: mode === 'items' }"
              @click="this.mode = 'items'"
            >
              items
            </span>
            <span
              style="margin-left: 3px"
              class="mode-switcher"
              :class="{ active: mode === 'urls' }"
              @click="this.mode = 'urls'"
            >
              urls
            </span>
          </th>
          <th>PROCESSED</th>
          <th>IN CACHE</th>
          <th>FETCHED</th>
          <th>ERRORED</th>
          <th>CHANGED</th>
          <th>ADDED</th>
        </tr>
        <tr>
          <td>last minute:</td>
          <td v-text="f(status.lastMinute[`${mode}Processed`])"></td>
          <td v-text="f(status.lastMinute[`${mode}ValidCache`])"></td>
          <td v-text="f(status.lastMinute[`${mode}UpdatedCache`])"></td>
          <td v-text="f(status.lastMinute.errored)"></td>
          <td v-text="f(status.lastMinute.fileChanged)"></td>
          <td v-text="f(status.lastMinute.fileAdded)"></td>
        </tr>
        <tr>
          <td>last hour:</td>
          <td v-text="f(status.lastHour[`${mode}Processed`])"></td>
          <td v-text="f(status.lastHour[`${mode}ValidCache`])"></td>
          <td v-text="f(status.lastHour[`${mode}UpdatedCache`])"></td>
          <td v-text="f(status.lastHour.errored)"></td>
          <td v-text="f(status.lastHour.fileChanged)"></td>
          <td v-text="f(status.lastHour.fileAdded)"></td>
        </tr>
        <tr>
          <td>today:</td>
          <td v-text="f(status.today[`${mode}Processed`])"></td>
          <td v-text="f(status.today[`${mode}ValidCache`])"></td>
          <td v-text="f(status.today[`${mode}UpdatedCache`])"></td>
          <td v-text="f(status.today.errored)"></td>
          <td v-text="f(status.today.fileChanged)"></td>
          <td v-text="f(status.today.fileAdded)"></td>
        </tr>
        <tr>
          <td>last 24 hours:</td>
          <td v-text="f(status.last24Hours[`${mode}Processed`])"></td>
          <td v-text="f(status.last24Hours[`${mode}ValidCache`])"></td>
          <td v-text="f(status.last24Hours[`${mode}UpdatedCache`])"></td>
          <td v-text="f(status.last24Hours.errored)"></td>
          <td v-text="f(status.last24Hours.fileChanged)"></td>
          <td v-text="f(status.last24Hours.fileAdded)"></td>
        </tr>
      </table>
      <queue-length-history />
    </collapse>
  </div>
</template>

<script>
export default {
  inject: ['store'],
  data() {
    return {
      mode: 'items'
    };
  },
  created() {
    this.store.queueStatus.subscribe(10000);
  },
  computed: {
    status() {
      return this.store.state.queueStatus;
    }
  },
  methods: {
    f(value) {
      try {
        return parseFloat(value).toLocaleString('de-DE');
      } catch {
        return value;
      }
    },
    reloadData() {
      this.store.queueStatus.get();
    }
  }
};
</script>

<style lang="scss">
.queue-status {
  position: relative;
  margin-bottom: 25px;
  p.queue-length {
    .label {
      display: inline-block;
      width: 138px;
    }
    .value {
      display: inline-block;
      width: 130px;
      text-align: right;
      font-weight: bold;
      color: $accent;
    }
  }
  .queue-status-table {
    th {
      width: 143px;
      padding-left: 20px;
      text-align: right;

      .mode-switcher {
        font-size: 13px;
        line-height: 13px;
        padding: 0;
        font-weight: normal;
        color: #a7a7a7;
        cursor: pointer;

        &.active {
          color: $accent;
          font-weight: bold;
          cursor: default;
        }
      }
    }
    th:nth-child(1) {
      padding-left: 0;
      width: 120px;
      text-align: left;
    }
    td {
      text-align: right;
      font-weight: bold;
      color: $accent;
    }
    td:nth-child(1) {
      text-align: left;
      font-weight: normal;
      color: $textDefault;
    }
  }
}
</style>
