import { createApp } from 'vue';
import Oruga from '@oruga-ui/oruga-next';
import '@oruga-ui/oruga-next/dist/oruga.css';
import mdiVue from 'mdi-vue/v3';
import * as mdijs from '@mdi/js';
import App from './App.vue';
import router from './router';
import store from './store';
import * as globalComponents from './global-components';

const app = createApp({
  ...App,
  provide() {
    return { store };
  }
});

Object.entries(globalComponents).forEach(([name, module]) => {
  app.component(name, module);
});
app.use(router);
app.use(Oruga);
app.use(mdiVue, { icons: mdijs });
app.mount('#app');
