import { reactive, readonly } from 'vue';
import * as libAdminUserData from '../lib/admin-user-data';

const state = reactive({
  users: [],
  errorMessage: ''
});

const methods = {
  get: () => {
    libAdminUserData.get().then(result => {
      state.users = result;
      state.errorMessage = '';
    });
  },
  update: user => {
    libAdminUserData.update(user).then(result => {
      state.users = result;
      state.errorMessage = '';
    });
  },
  create: user => {
    libAdminUserData.create(user).then(result => {
      if (result.error) {
        state.errorMessage = result.error;
        console.log(':: error creating a new user', result);
        return false;
      }
      state.users = result;
      state.errorMessage = '';
      return true;
    });
  }
};

export default { state: readonly(state), methods };
