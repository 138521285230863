export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    closeEvent: {
      type: String,
      default: 'close'
    }
  },
  data() {
    return {
      idCloseOnEscape: undefined,
      isRegistered: false,
      isCloseOnEscapeListening: true,
      closeModes: ['escape'],
      isVisibleCloseOnEscape: false, // fallback if prop not possible
      eventListenerEscape: e => {
        if (e.keyCode === 27) {
          this.closeOnMixinEvent();
        }
      },
      eventListenerClick: () => {
        this.closeOnMixinEvent();
      },
      eventCancelBubbel: e => {
        e.cancelBubble = true;
      }
    };
  },
  watch: {
    isVisible(value) {
      if (value) this.registerLayer();
      else this.deRegisterLayer();
    },
    isVisibleCloseOnEscape(value) {
      if (value) this.registerLayer();
      else this.deRegisterLayer();
    }
  },
  created() {
    this.idCloseOnEscape = String(Math.random()).substring(2);
  },
  mounted() {
    if (this.isVisible) this.registerLayer();
  },
  methods: {
    registerLayer() {
      if (this.isRegistered) return;

      if (this.closeModes.includes('escape')) {
        document.addEventListener('keyup', this.eventListenerEscape);
      }

      if (this.closeModes.includes('click')) {
        this.$el.addEventListener('click', this.eventCancelBubbel);
        setTimeout(() => {
          document.addEventListener('click', this.eventListenerClick);
        }, 0);
      }

      this.isRegistered = true;
    },
    deRegisterLayer() {
      if (!this.isRegistered) return;

      if (this.closeModes.includes('escape')) {
        document.removeEventListener('keyup', this.eventListenerEscape);
      }

      if (this.closeModes.includes('click')) {
        this.$el.removeEventListener('click', this.eventCancelBubbel);
        document.removeEventListener('click', this.eventListenerClick);
      }

      this.isRegistered = false;
    },
    closeOnMixinEvent() {
      // if (this.$store.getters['app/topLayer'] === this.idCloseOnEscape) {
      if (!this.isCloseOnEscapeListening) return;
      this.deRegisterLayer();
      this.$emit(this.closeEvent);
      // }
    },
    muteClose() {
      this.isCloseOnEscapeListening = false;
    },
    listenClose() {
      this.isCloseOnEscapeListening = true;
    }
  }
};
