import { getBaseUrl, deliverMock, readToken } from './util.js';
import mock from './mock/latest-items.json';

const IS_MOCK = false;

export async function get() {
  if (IS_MOCK) {
    return deliverMock('latest-items', mock);
  }
  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/queue/latest-urls`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      token: readToken()
    }
  }).then(data => data.json());
}
