import isBlacklisted from './is-blacklisted.js';
import { getBaseUrl, readToken } from './util.js';

export async function submitInjestUrl(url) {
  const body = JSON.stringify({ url });
  if (isBlacklisted(url)) return `Aborted! Url is blacklisted`;
  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/queue/video-url`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      token: readToken()
    },
    body
  }).then(data => data.text());
}
