<template>
  <div class="login">
    <div class="title">Please log in:</div>
    <hr />
    <div class="input">
      <span> user name: <input v-model="userName" type="text" /> </span>

      <span>password: <input v-model="password" type="password" /> </span>

      <button @click="login">Submit</button>
    </div>
    <p class="complaint" v-text="complaint" />
    <p>
      You can find user name and password in
      <a
        target="_blank"
        href="https://confluence.p7s1-tech.com/display/ADTECHWIKI/Credentials"
        >Confluence</a
      >.
    </p>
  </div>
</template>

<script>
import { login } from '../lib/login.js';

export default {
  emits: ['logged-in'],
  data() {
    return {
      userName: '',
      password: '',
      complaint: ''
    };
  },
  created() {
    const saved = JSON.parse(this.readFromLocal());
    if (saved?.name) {
      this.$emit('logged-in');
    }
  },
  methods: {
    async login() {
      try {
        const user = await login(this.userName, this.password);
        if (user.error) {
          this.complaint = user.error;
        }
        if (user.name) {
          this.saveToLocal(user);
          this.$emit('logged-in');
        }
      } catch (error) {
        console.log('error: ', error);
      }
    },
    async hash(input) {
      const buffer = await crypto.subtle.digest(
        'SHA-256',
        new TextEncoder().encode(input)
      );
      return btoa(
        Array.prototype.map
          .call(new Uint8Array(buffer), ch => String.fromCharCode(ch))
          .join('')
      );
    },
    readFromLocal() {
      return localStorage.getItem('apolloUser');
    },
    saveToLocal(value) {
      localStorage.setItem('apolloUser', JSON.stringify(value));
    }
  }
};
</script>

<style lang="scss">
.login {
  margin: 100px auto 0 auto;
  padding: 15px;
  width: 420px;
  border: 1px solid $accent;
  border-radius: 4px;

  .title {
    font-weight: bold;
  }
  .input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      display: inline-block;
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
  hr {
    margin: 10px -15px 10px -15px;
    background-color: $accent;
  }
  button {
    margin: 10px 0;
    display: block;
    align-self: center;
  }
  .complaint {
    color: red;
  }
  a {
    color: $accent;
    text-decoration: none;
  }
}
</style>
