import { getBaseUrl, deliverMock, readToken } from './util.js';
import mock from './mock/review-files.json';

const IS_MOCK = false;

export async function get() {
  if (IS_MOCK) {
    return deliverMock('review-files-info', mock);
  }
  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/review/files-info`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      token: readToken()
    }
  }).then(data => data.json());
}

export async function update(fingerprint, command) {
  const body = JSON.stringify({ fingerprint, command });
  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/review/update`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      token: readToken()
    },
    body
  }).then(data => data.json());
}
