import { getBaseUrl } from './util.js';

export async function login(userName, password) {
  const body = JSON.stringify({ userName, password });

  const baseUrl = getBaseUrl();
  return fetch(`${baseUrl}/login`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }).then(data => data.json());
}
