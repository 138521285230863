<template>
  <hr />
  <review-files />
</template>

<script>
export default {
  name: 'ReviewView',
  data() {
    return {
      isLoggedIn: false
    };
  },
  methods: {
    loggedIn() {
      this.isLoggedIn = true;
    }
  }
};
</script>
