<template>
  <div class="file-search">
    <collapse>
      <template v-slot:title>
        <h2>File Search</h2>
      </template>
      <reload-button @click="reload" />
      <!-- temporarily deactivated (clinch -> too many duplicates) -->
      <!-- <potential-duplicates
        :filesInfo="filesInfo"
        @set-search-value="setSearchValue"
      /> -->
      <section>
        <div class="label" style="font-size: 14px; line-height: 24px">
          url / lineitem / fingerprint:
        </div>
        <div class="form">
          <input type="text" v-model="searchName" />
        </div>
        <div class="more">
          <button @click="clearForm">clear</button>
        </div>
      </section>
      <div v-if="filesInfo.length">
        <collapse
          ref="filterCollapse"
          :collapsed="filtersCollapsed"
          @collapse="value => (this.filtersCollapsed = value)"
        >
          <template v-slot:title>
            <div
              style="cursor: pointer; font-weight: bold"
              v-show="filtersCollapsed"
              v-text="'› show more filters'"
            ></div>
          </template>
          <div class="filters">
            <section>
              <div class="slider-wrapper">
                <div class="slider-values">
                  <span v-text="duration.map(f).join(' - ') + ' sec.'"></span>
                </div>
                <o-slider
                  :min="durationRange[0]"
                  :max="durationRange[1]"
                  v-model="duration"
                  :step="0.001"
                  lazy
                />
                <div class="slider-label">duration</div>
              </div>
              <div class="slider-wrapper">
                <div class="slider-values">
                  <span v-text="fileSize.map(f).join(' - ') + ' Bytes'"></span>
                </div>
                <o-slider
                  :min="fileSizeRange[0]"
                  :max="fileSizeRange[1]"
                  v-model="fileSize"
                  :step="1"
                  lazy
                />
                <div class="slider-label">file size</div>
              </div>
              <div class="slider-wrapper">
                <div class="slider-values">
                  <span v-text="bitRate.map(f).join(' - ')"></span>
                </div>
                <o-slider
                  :min="bitRateRange[0]"
                  :max="bitRateRange[1]"
                  v-model="bitRate"
                  :step="1"
                  lazy
                />
                <div class="slider-label">bitrate</div>
              </div>
            </section>
            <section>
              <div class="slider-wrapper">
                <div class="slider-values">
                  <span v-text="height.map(f).join(' - ') + ' Pixel'"></span>
                </div>
                <o-slider
                  :min="heightRange[0]"
                  :max="heightRange[1]"
                  v-model="height"
                  :step="1"
                  lazy
                />
                <div class="slider-label">height</div>
              </div>
              <div class="slider-wrapper">
                <div class="slider-values">
                  <span v-text="width.map(f).join(' - ') + ' Pixel'"></span>
                </div>
                <o-slider
                  :min="widthRange[0]"
                  :max="widthRange[1]"
                  v-model="width"
                  :step="1"
                  lazy
                />
                <div class="slider-label">width</div>
              </div>
              <div class="slider-wrapper">
                <div class="slider-values">
                  <span v-text="added.map(timestamp2iso).join(' - ')"></span>
                </div>
                <o-slider
                  :min="addedRange[0]"
                  :max="addedRange[1]"
                  v-model="added"
                  :step="1"
                  :custom-formatter="timestamp2iso"
                  lazy
                />
                <div class="slider-label">added</div>
              </div>
            </section>
          </div>

          <div
            v-show="!filtersCollapsed"
            style="cursor: pointer; font-weight: bold"
            v-text="'⌃ show less filters'"
            @click="collapseFilters"
          ></div>
        </collapse>
        <div
          class="found-files"
          v-text="
            `${f(filteredTableData.length)} file${
              filteredTableData.length === 1 ? '' : 's'
            } found.`
          "
        />
      </div>
      <file-search-output-table
        :tableData="filteredTableData"
        @set-search-value="setSearchValue"
      />
    </collapse>
  </div>
</template>

<script>
import isBlacklisted from '../lib/is-blacklisted';
import { getBaseUrl, readToken } from '../lib/util';

const GOOGLE_DYNAMICURL_REGEX = /\/id\/\w+\/itag\/\d+\//;
function matchesDynamicUrlPart(url, searchName) {
  const [dynamicUrlPart] = searchName.match(GOOGLE_DYNAMICURL_REGEX) || [];
  return dynamicUrlPart && url.includes(dynamicUrlPart);
}

export default {
  inject: ['store'],
  data() {
    return {
      filtersCollapsed: true,
      searchName: '',
      durationRange: [1, 30],
      duration: [2, 6],
      bitRate: [1, 1000],
      bitRateRange: [1, 1000],
      fileSize: [1, 1000],
      fileSizeRange: [1, 1000],
      added: [1, 1e12],
      addedRange: [1, 1e12],
      width: [100, 200],
      widthRange: [100, 200],
      height: [100, 200],
      heightRange: [100, 200],
      tableData: []
    };
  },
  computed: {
    filesInfo() {
      return this.store.state.filesInfo.files;
    },
    filteredTableData() {
      const searchName = this.searchName;
      return this.tableData.filter(row => {
        const searchNamePasses =
          !searchName ||
          row.url.includes(searchName) ||
          matchesDynamicUrlPart(row.url, searchName) ||
          row.lineItem.includes(searchName) ||
          row.lineItems.some(li => li.includes(searchName)) ||
          row.textExtract.some(te =>
            te.toLowerCase().includes(searchName.toLowerCase())
          ) ||
          row.fingerprint?.startsWith(searchName);

        return (
          searchNamePasses &&
          row.duration >= this.duration[0] &&
          row.duration <= this.duration[1] &&
          row.bitrate >= this.bitRate[0] &&
          row.bitrate <= this.bitRate[1] &&
          row.size >= this.fileSize[0] &&
          row.size <= this.fileSize[1] &&
          row.added >= this.timestamp2iso(this.added[0]) &&
          row.added <= this.timestamp2iso(this.added[1] + 86400000) &&
          row.width >= this.width[0] &&
          row.width <= this.width[1] &&
          row.height >= this.height[0] &&
          row.height <= this.height[1]
        );
      });
    }
  },
  created() {
    this.store.filesInfo.get();
  },
  watch: {
    filesInfo() {
      this.initForm();
    }
  },
  methods: {
    collapseFilters() {
      this.$refs.filterCollapse.collapse();
      this.filtersCollapsed = true;
    },
    reload() {
      this.store.filesInfo.get();
      this.clearForm();
    },
    initForm() {
      this.searchName = '';
      const durationRange = this.getDurationRange();
      this.durationRange = durationRange;
      const bitrate = this.getBitRateRange();
      this.bitRateRange = bitrate;
      const fileSize = this.getFileSizeRange();
      this.fileSizeRange = fileSize;
      const added = this.getAddedRange();
      this.addedRange = added;
      const widthRange = this.getWidthRange();
      this.widthRange = widthRange;
      const heightRange = this.getHeightRange();
      this.heightRange = heightRange;
      const tableData = this.getTableData();
      this.tableData = tableData;

      setTimeout(this.clearForm, 1);
    },
    f(value) {
      if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(value)) {
        return value.substr(0, 19).replace('T', ' ');
      }
      if (typeof value !== 'number') return value;
      return value.toLocaleString('de-DE');
    },
    clearForm() {
      this.searchName = '';
      this.duration = this.durationRange;
      this.bitRate = this.bitRateRange;
      this.fileSize = this.fileSizeRange;
      this.added = this.addedRange;
      this.width = this.widthRange;
      this.height = this.heightRange;
    },
    setSearchValue(value) {
      this.clearForm();
      this.searchName = value;
    },
    getDurationRange() {
      return this.filesInfo.reduce(
        (acc, file) => {
          const duration = file.fileInfo?.format?.duration;
          if (!acc[0] || duration < acc[0]) acc[0] = duration;
          if (!acc[1] || duration > acc[1]) acc[1] = duration;
          return acc;
        },
        [0, 0]
      );
    },
    getBitRateRange() {
      return this.filesInfo.reduce(
        (acc, file) => {
          const bitrate = file.fileInfo?.format?.bitrate;
          if (!acc[0] || bitrate < acc[0]) acc[0] = bitrate;
          if (!acc[1] || bitrate > acc[1]) acc[1] = bitrate;
          return acc;
        },
        [0, 0]
      );
    },
    getFileSizeRange() {
      return this.filesInfo.reduce(
        (acc, file) => {
          const fileSize = file.fileInfo?.format?.size;
          if (!acc[0] || fileSize < acc[0]) acc[0] = fileSize;
          if (!acc[1] || fileSize > acc[1]) acc[1] = fileSize;
          return acc;
        },
        [0, 0]
      );
    },
    timestamp2iso(value) {
      return new Date(value).toISOString().substring(0, 10);
    },
    getAddedRange() {
      const iso2timestamp = iso => +new Date(iso);
      return this.filesInfo.reduce(
        (acc, file) => {
          const added = iso2timestamp(file.inserted);
          if (!acc[0] || added < acc[0]) acc[0] = added;
          if (!acc[1] || added > acc[1]) acc[1] = added;
          return acc;
        },
        [0, 0]
      );
    },
    getWidthRange() {
      return this.filesInfo.reduce(
        (acc, file) => {
          const width = file.fileInfo?.streams?.video?.width;
          if (!acc[0] || width < acc[0]) acc[0] = width;
          if (!acc[1] || width > acc[1]) acc[1] = width;
          return acc;
        },
        [0, 0]
      );
    },
    getHeightRange() {
      return this.filesInfo.reduce(
        (acc, file) => {
          const height = file.fileInfo?.streams?.video?.height;
          if (!acc[0] || height < acc[0]) acc[0] = height;
          if (!acc[1] || height > acc[1]) acc[1] = height;
          return acc;
        },
        [0, 0]
      );
    },
    calculateRatio(width, height) {
      const aspectRatios = {
        1.78: '16:9',
        1.6: '16:10',
        1.33: '4:3',
        2.67: '8:3',
        0.56: '9:16',
        0.63: '10:16',
        0.75: '3:4'
      };
      const quotient = width / height;
      const rounded = Math.round((quotient + Number.EPSILON) * 100) / 100;

      return aspectRatios[rounded] || quotient;
    },
    getTableData() {
      return this.filesInfo
        .map(file => {
          const {
            url,
            inserted,
            lastFetched,
            lineItems,
            textExtract,
            downloadDuration,
            fileInfo: {
              format: { bitrate, duration, size } = {},
              streams: { video: { width, height, codec } = {} } = {},
              headers,
              loudness,
              loudness: { fingerprint = '' } = {}
            } = {}
          } = file;

          if (isBlacklisted(url)) return '';

          const hasCookie = !headers
            ? '?'
            : Object.keys(headers).some(h => /cookie/i.test(h))
            ? 'yes'
            : 'no';
          let lineItem = '';
          if (Array.isArray(lineItems)) {
            lineItem =
              lineItems.length === 1
                ? lineItems[0]
                : `${lineItems.length} items`;
          }

          return {
            url,
            screenshotUrl: `${getBaseUrl()}/screenshot?url=${url}&token=${readToken()}`,
            added: inserted,
            lastSeen: lastFetched,
            hasCookie,
            loudness: loudness?.integrated || '?',
            loudnessHigh: loudness?.rangeHigh || '?',
            loudnessLow: loudness?.rangeLow || '?',
            bitrate,
            duration,
            size,
            width,
            height,
            lineItem,
            lineItems: lineItems || [],
            textExtract: textExtract || [],
            downloadDuration,
            fingerprint,
            codec,
            aspectRatio: this.calculateRatio(width, height),
            details: file
          };
        })
        .filter(Boolean);
    }
  }
};
</script>

<style lang="scss">
.file-search {
  position: relative;
  margin-top: 25px;
  margin-bottom: 100px;
  .filters {
    margin-left: 166px;
    .slider-wrapper {
      width: 300px;
      margin-right: 20px;
      .slider-values {
        text-align: center;
      }
      .slider-label {
        text-align: center;
        font-weight: bold;
      }
    }
  }
  section {
    display: flex;
    margin-bottom: 15px;
    input {
      width: 100%;
      padding: 3px;
      background: rgba(255, 255, 255, 0.75);
      border: 1px solid #999999;
      border-radius: 4px;
    }

    .label {
      width: 166px;
    }
    .form {
      width: 620px;
    }
    .more {
      margin-left: 20px;
    }
  }
  .found-files {
    margin: 10px 0 10px 166px;
    width: 620px;
    text-align: center;
    font-weight: bold;
  }
  .o-slide {
    padding: 7px 7px 0 7px;
  }
  .o-slide__track {
    height: 2px;
  }
  .o-slide__fill {
    background-color: rgba(18, 93, 133, 0.8);
    height: 2px;
  }
  .o-slide__thumb {
    border-radius: 4px;
  }
  .o-tip__content {
    color: white;
  }
}
</style>
