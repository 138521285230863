<template>
  <div class="review-files">
    <h2>Review mp4 files to approve for DAI playout</h2>

    <div class="filter-buttons">
      <div
        @click="setFilter('unReviewed')"
        :class="{ active: filter === 'unReviewed' }"
      >
        not reviewed yet
      </div>
      <div
        @click="setFilter('reviewed')"
        :class="{ active: filter === 'reviewed' }"
      >
        reviewed
      </div>
      <div @click="setFilter('all')" :class="{ active: filter === 'all' }">
        all
      </div>
    </div>

    <table class="main">
      <div class="row-count-wrapper" v-show="internalData.length > perPage">
        rows per page:
        <select v-model.number="perPage">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <tr>
        <th
          v-for="c in tableColumns"
          :key="c"
          v-text="getHeaderText(c.field)"
          @click="toggleSort(c.field)"
          :style="{
            textAlign: c.position || 'left',
            width: c.width ? `${c.width}px` : 'default'
          }"
        />
      </tr>
      <template v-for="(row, index) in currentPageContent" v-bind:key="row">
        <tr>
          <td v-for="c in tableColumns" v-bind:key="c.field" :class="c.field">
            <span v-if="c.field === 'link'">
              <mdicon
                @click="copyLink(row[c.field])"
                style="cursor: pointer; margin-right: 5px"
                size="18"
                name="content-copy"
              />
              <a target="_playout" :href="row[c.field]">
                <mdicon size="24" name="play-box-outline" />
              </a>
            </span>
            <span v-else-if="c.field === 'screenshot'">
              <img :src="row.screenshot" class="screenshot" />
              <img :src="row.screenshot" class="screenshot-bigger" />
            </span>
            <span v-else-if="c.field === 'approvalButtons'">
              <button
                @click="updateFileStatus(row.fingerprint, 'approved')"
                class="set-status approve"
              >
                approve
              </button>
              <button
                @click="updateFileStatus(row.fingerprint, 'denied')"
                class="set-status deny"
              >
                deny
              </button>
            </span>
            <div v-else-if="c.field === 'reviewStatus'">
              <div v-if="row.reviewStatus === 'approved'">
                <mdicon name="thumb-up" class="green" />
              </div>
              <div v-else-if="row.reviewStatus === 'denied'">
                <mdicon name="thumb-down" class="red" />
              </div>
              <div v-else>
                <mdicon name="timer-sand-empty" class="yellow" />
              </div>
            </div>

            <div v-else-if="c.field === 'fingerprint'">
              <span class="fingerprint" v-text="row[c.field]" />
              <div class="collapser" @click="toggleRow(index, row.fingerprint)">
                <mdicon
                  v-show="index !== enhancedRow"
                  name="chevron-right"
                  size="16"
                />
                <mdicon
                  v-show="index === enhancedRow"
                  name="chevron-down"
                  size="16"
                />
                {{ row.allUrls?.length }} file{{
                  row.allUrls?.length === 1 ? '' : 's'
                }}:
              </div>
            </div>

            <span v-else v-text="f(row[c.field])"> </span>
          </td>
        </tr>
        <tr v-if="index === enhancedRow">
          <td></td>
          <td colspan="6" class="detail-td">
            <div
              v-for="url in row.allUrls"
              v-bind:key="url"
              class="url-div"
              v-text="url"
            ></div>
            <h4 v-if="row.history?.length" style="margin: 15px 0 0px 1px">
              Review History:
            </h4>
            <table v-if="row.history?.length" class="history-table">
              <tr>
                <th>User</th>
                <th>Timestamp</th>
                <th>Set Value to</th>
              </tr>
              <tr v-for="item in row.history" v-bind:key="item">
                <td v-text="item.username" />
                <td v-text="item.review_timestamp" />
                <td v-text="item.status" />
              </tr>
            </table>
          </td>
        </tr>
      </template>
    </table>
    <section class="pagination" v-show="internalData.length > perPage">
      <o-pagination
        :total="internalData.length"
        v-model:current="currentPage"
        :per-page="perPage"
        range-before="0"
        range-after="0"
        order="centered"
        :icon-prev="'chevron-left'"
        :icon-next="'chevron-right'"
      >
      </o-pagination>
    </section>
  </div>
</template>

<script>
import { getBaseUrl, readToken } from '../lib/util';
import tableColumns from './review-files-table-columns.json';

export default {
  inject: ['store'],
  data() {
    return {
      sortDirection: 'ASC',
      sortField: undefined,
      enhancedRow: -1,
      currentPage: 1,
      perPage: 20,
      filter: 'all'
    };
  },
  computed: {
    tableColumns() {
      return tableColumns;
    },
    approveFilesInfo() {
      return this.store.state.reviewFiles.files;
    },
    approveFileHistory(fingerprint) {
      return this.store.state.reviewFiles.getFileHistory(fingerprint);
    },
    tableData() {
      return (this.approveFilesInfo || []).map(row => {
        const {
          fingerprint,
          history = [],
          items: [
            {
              inserted,
              url,
              review_status: reviewStatus = '',
              review_timestamp: reviewTimestamp
            }
          ] = [],
          items = []
        } = row;

        return {
          fingerprint,
          allUrls: items.map(({ url }) => url),
          added: inserted,
          screenshot: `${getBaseUrl()}/screenshot?url=${url}&token=${readToken()}`,
          link: url,
          history,
          reviewTimestamp,
          reviewStatus
        };
      });
    },
    internalData() {
      let filterFn = () => true;
      if (this.filter === 'unReviewed') {
        filterFn = item => !item.reviewStatus;
      } else if (this.filter === 'reviewed') {
        filterFn = item => !!item.reviewStatus;
      }

      return this.tableData.filter(filterFn).sort((a, b) => {
        if (this.sortDirection === 'ASC') {
          if (a[this.sortField] > b[this.sortField]) return 1;
          return -1;
        }
        if (a[this.sortField] > b[this.sortField]) return -1;
        return 1;
      });
    },
    currentPageContent() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.internalData.slice(start, start + this.perPage);
    }
  },
  created() {
    this.store.reviewFiles.get();
  },
  methods: {
    setFilter(state) {
      this.filter = state || 'all';
    },
    updateFileStatus(fingerprint, command) {
      this.store.reviewFiles.update(fingerprint, command);
    },
    f(value) {
      if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/.test(value)) {
        return value.substr(0, 10);
      }
      if (typeof value !== 'number') return value;
      return value.toLocaleString('de-DE');
    },
    getHeaderText(field) {
      const label = this.tableColumns.find(c => c.field === field).label;
      const sortSign =
        this.sortField === field
          ? this.sortDirection === 'ASC'
            ? '↑ '
            : '↓ '
          : '';
      return `${sortSign}${label}`;
    },
    toggleSort(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.sortField = field;
        this.sortDirection = 'ASC';
      }
      this.enhancedRow = -1;
      this.currentPage = 1;
    },
    copyLink(url) {
      navigator.clipboard.writeText(url);
    },
    toggleRow(index) {
      this.enhancedRow = this.enhancedRow === index ? -1 : index;
    }
  }
};
</script>

<style lang="scss">
.review-files {
  .filter-buttons {
    margin: 10px 0;
    border: 1px solid #444;
    border-radius: 4px;
    display: inline-block;
    div {
      display: inline-block;
      width: 140px;
      padding: 2px 0;
      text-align: center;
      border-right: 1px solid #444;
      cursor: pointer;
      &.active {
        font-weight: bold;
        background-color: #ccc;
        cursor: default;
      }
      &:last-child {
        border: none;
      }
    }
  }

  button.set-status {
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-right: 10px;

    &.approve {
      background-color: green;
    }
    &.deny {
      background-color: red;
    }
  }
  table.main {
    position: relative;
    margin-top: 25px;
    .row-count-wrapper {
      position: absolute;
      top: -25px;
      right: 0;
    }

    th {
      cursor: pointer;
    }
    td,
    th {
      padding: 4px;
      white-space: nowrap;
    }
    td {
      vertical-align: top;
    }
    td.fingerprint {
      position: relative;
      .fingerprint {
        display: inline-block;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 24px;
        font-family: 'Courier New', Courier, monospace;
      }
      .collapser {
        position: absolute;
        left: 0;
        bottom: 5px;
        cursor: pointer;

        span {
          display: inline-block;
          transform: translate(0, -1px);
        }
      }
    }
    td.link {
      a {
        color: #ff0031;
      }
    }
    td.reviewStatus {
      text-align: center;
      .red {
        color: #ff0031;
      }
      .green {
        color: green;
      }
      .yellow {
        color: rgb(255, 174, 0);
      }
    }
    td.screenshot {
      position: relative;
      height: 80px;
      line-height: 10px;
      img.screenshot {
        height: 80px;
      }
      img.screenshot-bigger {
        position: absolute;
        display: none;
        top: -200px;
        left: 160px;
        height: 480px;
      }
      &:hover {
        img.screenshot-bigger {
          display: block;
        }
      }
    }
    td.detail-td {
      position: relative;
      .url-div {
        max-width: 614px;
        overflow-x: auto;
      }
      .history-table {
        th {
          text-align: left;
          padding: 0 10px 0 0;
        }
        td {
          padding: 0 10px 0 0;
        }
      }
    }
  }
  .pagination {
    margin: 20px 0 50px 0;
    text-align: center;
    justify-content: center;

    .o-pag__link {
      border: 1px solid grey;
      border-radius: 4px;
      height: 30px;
      min-width: 30px;
      margin: 0 10px;
      color: $textDefault;

      &.o-pag__link--current {
        color: $accent;
        background: inherit;
        font-weight: bold;
      }
      &.o-pag__next::before {
        margin-top: -2px;
        font-weight: bold;
        content: '›';
      }
      &.o-pag__previous::before {
        margin-top: -2px;
        font-weight: bold;
        content: '‹';
      }
    }
  }
}
</style>
