<template>
  <div class="admin-user-management">
    <h2>User Management</h2>
    <table class="user-table">
      <tr>
        <th>Name</th>
        <th>Role</th>
        <th>Active</th>
      </tr>
      <tr v-for="user in myUsers" v-bind:key="user">
        <td v-text="user.name" />
        <td>
          <select @change="handleChanged(user.name)" v-model="user.role">
            <option value="default">default</option>
            <option value="reviewer">reviewer</option>
            <option value="admin">admin</option>
          </select>
        </td>
        <td>
          <input
            type="checkbox"
            @change="handleChanged(user.name)"
            v-model="user.approved"
          />
        </td>
      </tr>
    </table>

    <div class="explanation">
      <h4>User Roles</h4>
      <p><b>default:</b> can only see the dashboard</p>
      <p><b>reviewer:</b> can approve / reject video files for <b>DAI</b></p>
      <p><b>admin:</b> can manage users (this page)</p>
    </div>
    <br style="clear: both" />

    <div class="create-user">
      <h4>Create User</h4>
      <div>Name:<br /><input v-model="newUser.name" type="text" /></div>
      <div>
        Password:<br /><input v-model="newUser.password" type="text" />
        <button
          @click="newUser.password = generatePassword()"
          style="font-size: 10px; padding: 0 2px; margin-left: 10px"
        >
          gen
        </button>
        <mdicon
          @click="copyPassword"
          name="content-copy"
          size="16"
          style="margin-left: 10px"
        />
      </div>
      <button @click="createUser">Save</button><br />
      <p>
        Please copy the password before creating the user, you won't be able to
        see it afterwards.
      </p>
      <p class="error" v-text="errorMessageCreate" />
    </div>
  </div>
</template>

<script>
import { toRaw, reactive } from 'vue';

export default {
  inject: ['store'],
  name: 'AdminUserManagement',
  data() {
    return {
      myUsers: [],
      newUser: {}
    };
  },
  computed: {
    users() {
      return this.store.state.adminUserData.users;
    },
    errorMessageCreate() {
      return this.store.state.adminUserData.errorMessage;
    }
  },
  watch: {
    users(value) {
      this.myUsers = reactive(
        toRaw(value).sort((a, b) => (a.name < b.name ? -1 : 1))
      );
    }
  },
  mounted() {
    this.store.adminUserData.get();
  },
  methods: {
    handleChanged(value) {
      const changedItem = this.myUsers.find(u => u.name === value);
      this.store.adminUserData.update(changedItem);
    },
    async createUser() {
      try {
        const success = await this.store.adminUserData.create({
          name: this.newUser.name,
          password: this.newUser.password
        });
        if (success) {
          this.newUser.name = '';
          this.newUser.password = '';
        }
      } catch (_) {
        /* */
      }
    },
    generatePassword() {
      const chars =
        '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';

      for (var i = 0; i <= 12; i++) {
        var random = Math.floor(Math.random() * chars.length);
        result += chars.substring(random, random + 1);
      }
      return result;
    },
    copyPassword() {
      navigator.clipboard.writeText(this.newUser.password);
    }
  }
};
</script>

<style lang="scss">
.admin-user-management {
  margin-top: 50px;
  .user-table {
    float: left;
    margin-right: 100px;
    th {
      text-align: left;
    }
    th:nth-child(1) {
      width: 200px;
    }
    th:nth-child(2) {
      width: 100px;
    }
  }
  .explanation {
    margin-left: 10px;
    h4 {
      margin-bottom: 0;
    }
    p {
      margin: 2px 0;
    }
  }
  .create-user {
    margin-top: 40px;
    h4 {
      margin-bottom: 5px;
    }
    div {
      display: inline-block;
      margin-right: 20px;
    }
  }
  .error {
    color: red;
  }
}
</style>
