const BACKEND_URL = {
  local: 'http://localhost:8095',
  development: 'https://development.apollo.sqrt-5041.de',
  staging: 'https://staging.apollo.sqrt-5041.de',
  production: 'https://apollo.sqrt-5041.de'
};

const FORCE_PRODUCTION_BACKEND = false;

export function getBaseUrl() {
  const normalizedEnv = (
    process.env.VUE_APP_ENVIRONMENT || 'local'
  ).toLowerCase();

  return FORCE_PRODUCTION_BACKEND
    ? BACKEND_URL.production
    : BACKEND_URL[normalizedEnv];
}

const wait = msec => new Promise(resolve => setTimeout(resolve, msec));

export async function deliverMock(moduleName, mockResponse) {
  await wait(50);
  console.log(`::: ${moduleName} got mock response`);
  return mockResponse;
}

export function readToken() {
  try {
    return JSON.parse(localStorage.getItem('apolloUser')).token;
  } catch {
    return '';
  }
}

export function logout() {
  localStorage.removeItem('apolloUser');
}

export function hasRoleLevel(requiredRole) {
  const roles = ['default', 'reviewer', 'admin'];
  try {
    const { role } = JSON.parse(localStorage.getItem('apolloUser'));
    return role && roles.indexOf(role) >= roles.indexOf(requiredRole);
  } catch {
    return false;
  }
}
