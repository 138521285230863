<template>
  <hr />
  <QueueStatus />
  <hr />
  <LatestItems />
  <hr />
  <Injest />
  <hr />
  <FileSearch />
</template>

<script>
import QueueStatus from '../components/queue-status.vue';
import FileSearch from '../components/file-search.vue';
import LatestItems from '../components/latest-items.vue';
import Injest from '../components/injest.vue';

export default {
  name: 'HomeView',
  data() {
    return {
      isLoggedIn: false
    };
  },
  methods: {
    loggedIn() {
      this.isLoggedIn = true;
    }
  },
  components: { FileSearch, QueueStatus, LatestItems, Injest }
};
</script>
